import React, { useState } from "react";

import { Step, StepDisplay, Steps } from "interactive-tutorial/lib/Steps";
import { Scroller } from "interactive-tutorial/lib/Scroller";
import { TopBanner } from "./banner";
import { Container, Grid, Typography, useTheme } from "@material-ui/core";
// import { CodeEditor } from "./components/CodeEditor";
import { CodeEditor } from "interactive-tutorial/lib/CodeEditor";
import { NoShorcutRoot } from "interactive-tutorial/lib/Theme";

type CodeBlock = {
  id: string;
  code: string[];
};

const postTemplate = ({
  pageContext: { title, blocks, code },
}: {
  pageContext: { title: string; blocks: { text: string }[]; code: CodeBlock[] };
}) => {
  const theme = useTheme();
  const [current, setCurrent] = useState(0);

  const handleNextPrevious = (direction: "next" | "previous") => {
    if (direction === "next") {
      const newCurrent = current + 1;
      if (newCurrent < blocks.length) {
        setCurrent(newCurrent);
      }
    } else {
      const newCurrent = current - 1;
      if (newCurrent >= 0) {
        setCurrent(newCurrent);
      }
    }
  };

  const height = `calc(100vh - ${theme.spacing(
    6
  )}px -  ${theme.typography.h3.fontSize.toString()} * ${theme.typography.h3.lineHeight.toString()})`;

  return (
    <NoShorcutRoot>
      <TopBanner />
      <Typography variant="h3" component="h1">
        {title}
      </Typography>
      <Container
        maxWidth="lg"
        style={{ height, maxHeight: height, overflow: "hidden" }}
      >
        <Grid container spacing={1} style={{ width: "100%", height: "100%" }}>
          <Grid item style={{ flexBasis: "50%", flexGrow: 0, height: "100%" }}>
            <Scroller
              display={current}
              style={{ height: "100%" }}
              onNext={() => handleNextPrevious("next")}
              onPrevious={() => handleNextPrevious("previous")}
              marginOffset={theme.spacing(2) + 5}
            >
              {blocks
                .map((b, idx) => {
                  let display = StepDisplay.FUTURE;
                  if (current === idx) {
                    display = StepDisplay.CURRENT;
                  } else if (current > idx) {
                    display = StepDisplay.PAST;
                  }
                  return { text: b.text, display };
                })
                .map((step, idx) => (
                  <Step key={idx} step={step.text} display={step.display} />
                ))}
            </Scroller>
          </Grid>
          <Grid item style={{ flexBasis: "50%" }}>
            <CodeEditor
              readonly
              code={code[0].code}
              onChange={(_newCode) => {}}
            />
          </Grid>
        </Grid>
      </Container>
    </NoShorcutRoot>
  );
};

export default postTemplate;
