import React from "react";

import { Grid, Typography, makeStyles, Paper } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

type StepsProps = {
  steps: string[];
  current: number;
};

export function Steps({ steps, current }: StepsProps) {
  return (
    <Grid
      container
      direction="column"
      style={{
        flexWrap: "nowrap",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      {steps.map((step, idx) => {
        let display = StepDisplay.FUTURE;
        if (current === idx) {
          display = StepDisplay.CURRENT;
        } else if (current > idx) {
          display = StepDisplay.PAST;
        }
        return <Step key={idx} step={step} display={display} />;
      })}
    </Grid>
  );
}

export enum StepDisplay {
  CURRENT,
  PAST,
  FUTURE,
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  stepIcon: {
    flexBasis: "30px",
    backgroundColor: theme.palette.primary.dark,
    paddingLeft: "3px",
    fontSize: "32px",
  },
  stepText: {
    flexBasis: "calc(100% - 30px)",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1.2),
  },
  current: {},
  past: {
    opacity: 0.4,
  },
}));

export function Step({
  step,
  display,
}: {
  step: string;
  display: StepDisplay;
}) {
  const classes = useStyles();

  return (
    <Grid
      item
      className={`
        ${classes.root} 
        ${display === StepDisplay.CURRENT ? classes.current : classes.past}
      `}
    >
      <Paper variant="outlined">
        <Grid container>
          <Grid item className={classes.stepIcon}>
            {display === StepDisplay.PAST ? (
              <CheckCircleIcon />
            ) : display === StepDisplay.CURRENT ? (
              <PlayCircleFilledIcon />
            ) : (
              <MoreHorizIcon />
            )}
          </Grid>
          <Grid item className={classes.stepText}>
            <Typography>
              {display !== StepDisplay.FUTURE ? (
                step
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: step
                      .split("")
                      .map((_) => "&nbsp; ")
                      .join(""),
                  }}
                ></span>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
