import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { lime } from "@material-ui/core/colors";

import { EditorState } from "@codemirror/state";
import { lineNumbers, highlightActiveLineGutter } from "@codemirror/gutter";
import {
  EditorView,
  keymap,
  highlightActiveLine,
  Decoration,
  ViewPlugin,
  DecorationSet,
  ViewUpdate,
} from "@codemirror/view";
import { defaultKeymap } from "@codemirror/commands";
import { oneDark } from "@codemirror/theme-one-dark";
import { RangeSetBuilder } from "@codemirror/rangeset";

const higlightColor = lime[900];
const activeHiglightColor = lime[400];

const useStyles = makeStyles((theme) => ({
  root: {},
  highlightLine: {
    backgroundColor: higlightColor,
    color: theme.palette.getContrastText(higlightColor),
    "&.cm-activeLine": {
      backgroundColor: activeHiglightColor,
      color: theme.palette.getContrastText(activeHiglightColor),
    },
  },
}));

type CodeEditorProps = {
  code: string[];
  highlightLines?: number[];
  readonly?: boolean;
  onChange: (newCode: string[]) => void;
};

export function CodeEditor({
  code,
  onChange,
  highlightLines,
  readonly,
}: CodeEditorProps) {
  const classes = useStyles();
  const textAreaRef = useRef();

  const higlighter = ViewPlugin.fromClass(
    class {
      decorations: DecorationSet;

      constructor(view: EditorView) {
        this.decorations = this.findRange(view);
      }
      update(update: ViewUpdate) {
        if (!update.docChanged) {
          return;
        }
        let builder = new RangeSetBuilder<Decoration>();
        this.decorations = builder.finish();
      }

      private findRange(view: EditorView) {
        const builder = new RangeSetBuilder<Decoration>();
        if (!highlightLines) {
          return builder.finish();
        }

        const stripe = Decoration.line({
          attributes: { class: classes.highlightLine },
        });

        highlightLines.forEach((lineNumber) => {
          try {
            const line = view.state.doc.line(lineNumber + 1);
            builder.add(line.from, line.from, stripe);
          } catch (e) {}
        });
        return builder.finish();
      }
    },
    {
      decorations: (v) => {
        return v.decorations;
      },
    }
  );

  const editable = EditorView.editable.of(readonly !== true);

  const handleUpdate = EditorView.updateListener.of((newView) => {
    if (!newView.docChanged) {
      return;
    }
    const newCode = [];
    const cursor = newView.state.doc.iterLines();
    cursor.next();
    while (!cursor.done) {
      newCode.push(cursor.value);
      cursor.next();
    }
    onChange(newCode);
    return true;
  });

  useEffect(() => {
    const indentedCode = code.join("\n");
    const state = EditorState.create({
      doc: indentedCode,
      extensions: [
        lineNumbers({}),
        highlightActiveLineGutter(),
        highlightActiveLine(),
        keymap.of(defaultKeymap),
        oneDark,
        handleUpdate,
        higlighter,
        editable,
      ],
    });

    const view = new EditorView({
      state: state,
      parent: textAreaRef.current,
    });

    return () => view.destroy();
  }, [code, highlightLines, readonly]);

  return <div className={classes.root} ref={textAreaRef}></div>;
}
