import { Grid } from "@material-ui/core";
import React, { CSSProperties, useRef, useEffect } from "react";

export function Scroller({
  children,
  onNext,
  onPrevious,
  style,
  display = 0,
  marginOffset = 0,
}: {
  children: React.ReactNode;
  onNext: () => void;
  onPrevious: () => void;
  style?: CSSProperties;
  display?: number;
  marginOffset?: number;
}) {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const child = ref.current.children.item(display) as HTMLElement;
    if (!child) {
      return;
    }
    const parentOffset = ref.current.offsetTop + marginOffset;
    ref.current.scroll({
      top: child.offsetTop - parentOffset,
    });
  }, [ref, display]);

  return (
    <Grid
      ref={ref}
      direction="column"
      style={{
        ...style,
        overflow: "hidden",
        flexWrap: "nowrap",
        height: "100%",
        maxHeight: "100%",
      }}
      onWheel={(e) => {
        const y = e.deltaY;
        y > 0 ? onNext() : onPrevious();
      }}
    >
      {children}
    </Grid>
  );
}
